import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

interface InputFieldProps {
  label: string;
  register: UseFormRegister<any>; // Consider using a more specific type instead of any
  name: string;
  errors: FieldErrors;
  type?: string;
  description?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  register,
  name,
  errors,
  type = "text",
  description,
}) => (
  <div className="flex gap-2 mt-2  items-center">
    <label className="min-w-48 text-sm font-semibold ">
      {label}{" "}
      <span>
        {description && <p className="text-xs text-gray-600">{description}</p>}{" "}
      </span>
    </label>

    <input
      className="block flex-1 max-w-lg border-1 rounded-sm border-black bg-gray-200 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
      type={type}
      {...register(name)}
    />
    {/* Correctly render the error message if it exists */}
    <div className="text-sm text-red-700">
      {errors[name] && <p>{errors[name]?.message as string}</p>}
    </div>

    {/* Render description if provided */}
  </div>
);

export default InputField;
