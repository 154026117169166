import React from "react";

interface VehicleProps {
  pageNumber: number;
  text: string;
}

const FormHeading: React.FC<VehicleProps> = ({ pageNumber, text }) => {
  return (
    <div className="bg-gray-200 p-3 mt-14 mb-2 rounded-lg">
      <div className="text-black flex flex-row gap-5 items-center">
        <p className=" bg-zinc-900 border text-white font-extrabold border-white w-10 h-10 text-center flex items-center justify-center rounded-full">
          {pageNumber}
        </p>
        <h4 className="text-xl font-bold">{text}</h4>
      </div>
    </div>
  );
};

export default FormHeading;
