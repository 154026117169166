import React from "react";
import { UseFormRegister } from "react-hook-form";

interface RadioButtonGroupProps {
  register: UseFormRegister<any>;
  name: string;
  label: string;
  description?: string;
  options: { label: string; value: string }[]; // Updated type to string
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  register,
  name,
  label,
  description,
  options,
}) => (
  <div className=" flex items-center py-5">
    <div className="min-w-[800px]">
      <h3 className="text-lg font-semibold text-gray-800 max-w-3xl">{label}</h3>
      {description && (
        <p className="text-sm text-gray-600  max-w-3xl">{description}</p>
      )}
    </div>
    <div className="flex flex-row gap-10">
      {options.map((option, index) => (
        <label
          key={index}
          className="flex items-center space-x-2 cursor-pointer"
        >
          <input
            type="radio"
            value={option.value}
            className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
            {...register(name)}
          />
          <span className="text-md text-gray-800">{option.label}</span>
        </label>
      ))}
    </div>
  </div>
);

export default RadioButtonGroup;
