import React from "react";
import {
  UseFormRegister,
  //   FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayAppend,
} from "react-hook-form";
import { TableRow } from "../form/FormValues";

interface TableComponentProps {
  fields: TableRow[];
  register: UseFormRegister<any>;
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<any, any>;
}

const TableComponent: React.FC<TableComponentProps> = ({
  fields,
  register,
  remove,
  append,
}) => (
  <div className="relative overflow-x-auto shadow-2xl sm:rounded w-full mt-10 bg-gray-50 p-5">
    <table className="w-full text-sm text-left text-gray-700 table table-fixed ">
      <thead className="text-xs text-gray-800 uppercase   ">
        <tr className="text-center bg-white-400">
          <th className="pt-10">Date of Payment</th>
          <th className="w-[10%]" colSpan={2}>
            Amount
          </th>
          <th className="pt-10">Type of Entertainment</th>
          <th className="pt-10">Location/Venue</th>
          <th colSpan={3}>Attendees</th>
          <th className="w-[5%]"></th>
        </tr>
        <tr className="text-center">
          <th></th>
          <th className="w-[5%]">GST ex</th>
          <th>GST inc</th>
          <th></th>
          <th></th>
          <th>
            # Employees <br />
            <span className="text-[9px]">(including spouses, relations)</span>
          </th>
          <th># Clients</th>
          <th>
            Owner <br />
            <span className="text-[9px]">(Self Employed)</span>
          </th>
          <th className="w-[5%]"></th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field, index) => (
          <tr className="bg-white border-b  text-center" key={field.id}>
            <td className="px-1">
              <input
                type="date"
                className="border border-gray-300 px-1 rounded focus:ring-blue-500 focus:border-blue-500 w-full"
                {...register(`tableRows.${index}.dateOfPayment`)}
              />
            </td>
            <td className="px-1">
              <input
                type="number"
                className="border border-gray-300 px-1 rounded focus:ring-blue-500 focus:border-blue-500 w-full"
                {...register(`tableRows.${index}.amountExGST`)}
              />
            </td>
            <td className="px-1">
              <input
                type="number"
                className="border border-gray-300 px-1 rounded focus:ring-blue-500 focus:border-blue-500 w-full"
                {...register(`tableRows.${index}.amountIncGST`)}
              />
            </td>
            <td className="px-1">
              <input
                className="border border-gray-300 px-1 rounded focus:ring-blue-500 focus:border-blue-500 w-full"
                {...register(`tableRows.${index}.typeOfEntertainment`)}
              />
            </td>
            <td className="px-1">
              <input
                className="border border-gray-300 px-1 rounded focus:ring-blue-500 focus:border-blue-500 w-full"
                {...register(`tableRows.${index}.locationVenue`)}
              />
            </td>
            <td className="px-1">
              <textarea
                className="border border-gray-300 px-1 rounded focus:ring-blue-500 focus:border-blue-500 w-full"
                {...register(`tableRows.${index}.numEmployees`)}
              />
            </td>
            <td className="px-1">
              <textarea
                className="border border-gray-300 px-1 rounded focus:ring-blue-500 focus:border-blue-500 w-full"
                {...register(`tableRows.${index}.numClients`)}
              />
            </td>
            <td className="px-1">
              <textarea
                className="border border-gray-300 px-1 rounded focus:ring-blue-500 focus:border-blue-500 w-full"
                {...register(`tableRows.${index}.owner`)}
              />
            </td>
            <td className="px-1">
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold px-1 rounded"
                type="button"
                onClick={() => remove(index)}
              >
                X
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <button
      className="bg-zinc-800 px-3 py-2 mt-2 rounded-md text-white text-sm"
      type="button"
      onClick={() =>
        append({
          dateOfPayment: "",
          amountExGST: 0,
          amountIncGST: 0,
          typeOfEntertainment: "",
          locationVenue: "",
          numEmployees: "",
          numClients: "",
          owner: "",
        })
      }
    >
      Add Row
    </button>
  </div>
);

export default TableComponent;
