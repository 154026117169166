import { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

export const useIPFetcher = (setValue: UseFormSetValue<any>) => {
  const [ip, setIp] = useState<string>('');

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIp(data.ip);
        setValue('ipAddress', data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIP();
  }, [setValue]);

  return ip;
};