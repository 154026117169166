import * as yup from "yup";

// Yup validation schema for a single table row
const tableRowSchema = yup.object({
  dateOfPayment: yup.string().required("Date of payment is required"),
  amountExGST: yup
    .number()
    .positive()
    .required("Amount excluding GST is required"),
  amountIncGST: yup
    .number()
    .positive()
    .required("Amount including GST is required"),
  typeOfEntertainment: yup
    .string()
    .required("Type of entertainment is required"),
  locationVenue: yup.string().required("Location/Venue is required"),
  numEmployees: yup.string().required("Number of employees is required"),
  numClients: yup.string().required("Number of clients is required"),
  owner: yup.string().required("Owner field is required"),
});
// Main Yup validation schema for the form
export const schema = yup
  .object({
    clientName: yup.string().required("Client Name is required"),
    contactPerson: yup.string().required("Contact Person is required"),
    emailAddress: yup
      .string()
      .email("Must be a valid email")
      .required("Email Address is required"),
    nonCashBenefitProvided: yup.string().required("This field is required"),
    odometerReadingAtMarch2021: yup.string().required("This field is required"),
    provideCarToEmployees: yup.string().required("This field is required"),
    payExpensesForPersonalVehicle: yup
      .string()
      .required("This field is required"),
    reimburseLeasePayments: yup.string().required("This field is required"),
    sellVehicleAtLessThanMarketValue: yup
      .string()
      .required("This field is required"),
    renewExtendOrPayOutMotorVehicleLeases: yup
      .string()
      .required("This field is required"),
    provideLoansToEmployeesOrDirectors: yup
      .string()
      .required("This field is required"),
    releaseEmployeesFromDebt: yup.string().required("This field is required"),
    paidExpensesOnBehalf: yup.string().required("This field is required"),
    provideRightToUseHouse: yup.string().required("This field is required"),
    provideMealsUnderEmploymentArrangement: yup
      .string()
      .required("This field is required"),
    provideReimbursementEmploymentArrangement: yup
      .string()
      .required("This field is required"),
    provideLivingAwayAllowance: yup.string().required("This field is required"),
    providePropertyGoodsNormallySold: yup
      .string()
      .required("This field is required"),
    providePropertyGoodsNotNormallySold: yup
      .string()
      .required("This field is required"),
    provideCarParkingFacilities: yup
      .string()
      .required("This field is required"),
    paidEntertainmentExpenses: yup.string().required("This field is required"),
    paidOtherBenefits: yup.string().required("This field is required"),
    companyPaidPersonalExpenses: yup
      .string()
      .required("This field is required"),
    employeeOwedMoneyToCompany: yup.string().required("This field is required"),
    reviewCashBookAndFinancialStatements: yup
      .string()
      .required("This field is required"),
    performFBTReview: yup.string().required("This field is required"),
    salaryPackagingAdvice: yup.string().required("This field is required"),
    yourName: yup.string().required("This field is required"),
    ipAddress: yup.string(),
    tableRows: yup.array().of(tableRowSchema),
  })
  .required();
