import "../App.css";

import React, { useRef } from "react";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SignatureCanvas from "react-signature-canvas";
import InputField from "../components/FormFields/InputFields";
import RadioButtonGroup from "../components/FormFields/RadioButton";
import TableComponent from "../components/TableComponent";
import { useIPFetcher } from "../hooks/userIPFetcher";
import { schema } from "./schema";
import { FormValues, TableRow } from "./FormValues";
import FormHeading from "../components/FormHeading";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";

function FringeBenfitsTaxQuestionnaire() {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      nonCashBenefitProvided: "false",
      odometerReadingAtMarch2021: "false",
      provideCarToEmployees: "false",
      payExpensesForPersonalVehicle: "false",
      reimburseLeasePayments: "false",
      sellVehicleAtLessThanMarketValue: "false",
      renewExtendOrPayOutMotorVehicleLeases: "false",
      provideLoansToEmployeesOrDirectors: "false",
      releaseEmployeesFromDebt: "false",
      paidExpensesOnBehalf: "false",
      provideRightToUseHouse: "false",
      provideMealsUnderEmploymentArrangement: "false",
      provideReimbursementEmploymentArrangement: "false",
      provideLivingAwayAllowance: "false",
      providePropertyGoodsNormallySold: "false",
      providePropertyGoodsNotNormallySold: "false",
      provideCarParkingFacilities: "false",
      paidEntertainmentExpenses: "false",
      paidOtherBenefits: "false",
      companyPaidPersonalExpenses: "false",
      employeeOwedMoneyToCompany: "false",
      reviewCashBookAndFinancialStatements: "false",
      performFBTReview: "false",
      salaryPackagingAdvice: "false",
      // yourName: "false",
      tableRows: [
        {
          dateOfPayment: "",
          amountExGST: 0,
          amountIncGST: 0,
          typeOfEntertainment: "",
          locationVenue: "",
          numEmployees: "",
          numClients: "",
          owner: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tableRows",
  });

  const watchPaidEntertainmentExpenses = watch(
    "paidEntertainmentExpenses",
    "false"
  ); // Default to "false"

  const watchprovideCarToEmployees = watch("provideCarToEmployees", "false");

  const sigCanvasRef = useRef<SignatureCanvas>(null);

  const ipAddress = useIPFetcher(setValue);

  const handleSaveForLater = () => {
    const formData = getValues();
    const signatureDataUrl = sigCanvasRef.current?.toDataURL();
    const dataToSave = {
      ...formData,
      signature: signatureDataUrl,
    };
    localStorage.setItem("formData", JSON.stringify(dataToSave));

    toast.success("Form Data Saved Successfully");
  };

  // Function to handle 'Restore Data'
  const handleRestoreData = () => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      const parsedData: FormValues = JSON.parse(savedData);
      for (const [key, value] of Object.entries(parsedData)) {
        if (key === "tableRows" && Array.isArray(value)) {
          // Assuming setValue can accept the entire array for tableRows
          setValue(key as keyof FormValues, value as TableRow[]);
        } else {
          // For other fields, assert the type based on your knowledge of what it should be
          setValue(key as keyof FormValues, value as string);
        }
      }
      // Restore the signature from saved data
      if (parsedData.signature) {
        sigCanvasRef.current?.fromDataURL(parsedData.signature as string);
      }
      toast.success("Successfully restored!");
    } else {
      toast.error("No data found, please fill again");
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    // Convert the signature to a data URL
    const signatureDataUrl = sigCanvasRef.current?.toDataURL();
    const submissionData = {
      ...data,
      signature: signatureDataUrl,
    };
    console.log(submissionData);

    try {
      // Show a SweetAlert2 loading alert
      Swal.fire({
        title: "Submitting your form...",
        html: "Please wait while we process your submission.",
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await fetch(
        "https://gx99qmgn8f.execute-api.ap-southeast-2.amazonaws.com/default/eea-advisory-fringe-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submissionData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      // Close the loading alert
      Swal.close();

      // Show success message with actions
      Swal.fire({
        icon: "success",
        title: "Form submitted successfully!",
        showCancelButton: false,
        confirmButtonText: "Redirecting in 3 Seconds",
        cancelButtonText: "Close window",
        reverseButtons: false,
      });

      handleSaveForLater();

      setTimeout(() => {
        // Redirect to the homepage
        // If you're using React Router, you can use history.push('/')
        window.location.href = "/";
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      // Show error message
      Swal.fire({
        icon: "error",
        title: "Failed to submit form",
        text: "Please report this to eea-advisory.com.au, you may save the progress for later use",
        showConfirmButton: true,
      });
    }
  };

  const today = new Date().toISOString().split("T")[0];

  return (
    <div className=" min-h-screen  w-full">
      <div>
        <Toaster position="bottom-center" reverseOrder={false} />
      </div>
      <div className="min-h-[20vh]  flex-col w-full text-start p-10 relative overflow-hidden">
        <img
          src="https://images.unsplash.com/photo-1578580620617-c4addbce9fe6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />

        <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>

        <Link to={"/"}>
          <img alt="EEA Advisory Logo" src="/logo.png" />
        </Link>
        <span className="text-gray-400">V.1.0.0</span>
        <h1 className="text-5xl font-bold text-white mt-10">
          Fringe Benefits Tax Questionnaire
        </h1>
        <p className="text-cyan-400 pt-10">Year Ending 31 March 2024</p>
        <p className="max-w-5xl mt-5 text-white text-sm">
          The Fringe Benefits Tax Questionnaire is an essential tool aimed at
          identifying and valuing non-cash benefits provided to employees,
          ensuring accurate tax reporting. It covers a wide range of benefits
          including company cars, health insurance, and educational assistance.
          This comprehensive questionnaire simplifies the process for businesses
          to meet their tax obligations efficiently.
        </p>
      </div>
      <div className="flex   bg-gray-300/10 p-10 rounded-lg w-full">
        <div className="w-full p-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              label="Client Name"
              name="clientName"
              register={register}
              errors={errors}
            />

            <InputField
              label="Contact Person"
              name="contactPerson"
              register={register}
              errors={errors}
            />

            <InputField
              label="Email Address"
              type="email"
              name="emailAddress"
              register={register}
              errors={errors}
            />
            <RadioButtonGroup
              register={register}
              name="nonCashBenefitProvided"
              label="Has any non-cash benefit been provided?"
              description="This may include benefits provided by associates or third-party arrangements to employees or their associates."
              options={[
                { label: "No", value: "false" }, // Using string representations
                { label: "Yes", value: "true" },
              ]}
            />

            <h3 className="mt-10">
              There are different types of Fringe Benefits to consider. In order
              to determine which ones apply to you for the period 1 April 2020
              to 31 March 2021, please answer the following questions
            </h3>

            {/* <div className="bg-zinc-800 p-5 mt-10 mb-5">
              <div className="text-white  flex flex-row gap-9 items-center">
                <p className="bg-blue-500 w-10 h-10 text-center flex items-center justify-center rounded-full">
                  1
                </p>
                <h4 className="text-2xl font-bold">Motor Vehicles</h4>
              </div>
            </div> */}
            <FormHeading pageNumber={1} text="Motor Vehicles" />

            <RadioButtonGroup
              register={register}
              name="provideCarToEmployees"
              label="Do you provide a car to employees (including working directors)?"
              description="If yes, complete the Motor Vehicle Schedule."
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="odometerReadingAtMarch2021"
              label="Have you taken an odometer reading at 31 March 2021 for each vehicle provided?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="payExpensesForPersonalVehicle"
              label="Do you pay expenses for any employee’s or associates personal vehicle (e.g. fuel)?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="reimburseLeasePayments"
              label="Do you reimburse lease payments?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="sellVehicleAtLessThanMarketValue"
              label="Have you sold a motor vehicle to an employee at less than market value?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="renewExtendOrPayOutMotorVehicleLeases"
              label="Have any motor vehicle leases been renewed, extended or paid out?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            {/* <InputField
              label="Employee Name"
              name="employeeName"
              register={register}
              errors={errors}
            /> */}

            {watchprovideCarToEmployees === "true" && (
              <div>
                <h3 className="text-2xl font-bold mt-10">
                  Motor Vehicle Schedule
                </h3>
                <h4 className="font-bold">
                  For vehicles owned between 1 April 2020 and 31 March 2021
                </h4>

                <div>
                  <InputField
                    label="Employee Name"
                    name="employeeName"
                    register={register}
                    errors={errors}
                  />
                </div>

                <h4 className="font-bold mt-5">
                  Complete this section if not already provided
                </h4>

                <div className="bg-gray-300 p-3 mt-5">
                  <p className="font-bold">Odometer Readings</p>
                </div>
                <InputField
                  label="Odometer Reading at 01/04/2020 (or when first used by employee)"
                  name="odometerStart"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Odometer Reading at 31/03/2021 (or when last used by employee)"
                  name="odometerEnd"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Number of Days Vehicle Was Not Available for Private Use"
                  name="daysNotAvailable"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Details of Expenses Paid Personally by Employee/Director (or their associate)"
                  name="personalExpensesDetails"
                  register={register}
                  errors={errors}
                />

                <div className="bg-gray-300 p-3 mt-5">
                  <p className="font-bold">If a logbook has been prepared</p>
                </div>

                <InputField
                  label="During What Period Was the Logbook Prepared?"
                  name="logbookPeriod"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Business Use % per Log Book*"
                  name="businessUsePercentage"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Motor Vehicle Expenditure Between 01/04/2020 and 31/03/2021 (GST Inclusive)"
                  name="motorVehicleExpenditure"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Fuel and Oil"
                  name="expenditureFuelOil"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Repairs and Maintenance"
                  name="expenditureRepairsMaintenance"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Insurance"
                  name="expenditureInsurance"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Registration"
                  name="expenditureRegistration"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Lease Payments (do not include hire purchase or loan repayments)"
                  name="expenditureLeasePayments"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Non-business Accessories (e.g., air con; stereo)"
                  name="expenditureNonBusinessAccessories"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Other (please specify)"
                  name="expenditureOther"
                  register={register}
                  errors={errors}
                />

                <div className="bg-gray-300 p-3 mt-5">
                  <p className="font-bold">Newly Acquired Vehicles</p>
                </div>
                <InputField
                  label="Type of Finance"
                  name="financeType"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Purchase Price** (provide copy of tax invoice)"
                  name="purchasePrice"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Date of Purchase"
                  name="dateOfPurchase"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Registration Number"
                  name="registrationNumber"
                  register={register}
                  errors={errors}
                />

                <InputField
                  label="Make/Model/Year"
                  name="vehicleMakeModelYear"
                  register={register}
                  errors={errors}
                />

                <div className="mt-10">
                  <p>
                    * Travel between home and work is not business related
                    unless home is the same place as employment
                  </p>
                </div>
                <div>
                  <p>
                    ** Purchase price includes GST, dealer delivery charges, and
                    luxury car tax, but excludes stamp duty and transfer fees.
                    If the car provided is leased, the cost is the market value
                    of the car.
                  </p>
                </div>
              </div>
            )}

            <FormHeading pageNumber={2} text="Loan Benefits" />
            <RadioButtonGroup
              register={register}
              name="provideLoansToEmployeesOrDirectors"
              label="Have you provided any loans to employees or directors?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <FormHeading pageNumber={3} text="Debt Waiver" />

            <RadioButtonGroup
              register={register}
              name="releaseEmployeesFromDebt"
              label="Have you released any employees from a debt that was previously owed to you?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <FormHeading pageNumber={4} text="Expenses Payment" />

            <RadioButtonGroup
              register={register}
              name="paidExpensesOnBehalf"
              label="Have you paid any expenses on behalf of any employees or directors?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <FormHeading pageNumber={5} text="Housing" />
            <RadioButtonGroup
              register={register}
              name="provideRightToUseHouse"
              label="Has any employee or director been provided with a right to use a house, etc as their usual place of residence?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <FormHeading pageNumber={6} text="Board" />
            <RadioButtonGroup
              register={register}
              name="provideMealsUnderEmploymentArrangement"
              label="Do you provide meals to employees or directors as a result of an industrial award or under an employment arrangement?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="provideReimbursementEmploymentArrangement"
              label="Have you reimburse employees or directors for any expenses 
              paid by them"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <FormHeading
              pageNumber={7}
              text="Living Away From Home Allowance"
            />
            <RadioButtonGroup
              register={register}
              name="provideLivingAwayAllowance"
              label="Do you provide employees or directors an allowance when they are required to live away from their usual place of residence?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <FormHeading pageNumber={8} text="Property Benefits" />
            <RadioButtonGroup
              register={register}
              name="providePropertyGoodsNormallySold"
              label="Provide Property/Goods Normally Sold"
              description="Do you provide property or goods to employees or directors which are normally sold as part of the business?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="providePropertyGoodsNotNormallySold"
              label="Provide Property/Goods Not Normally Sold"
              description="Do you provide property or goods to employees or directors which are not normally sold as part of the business?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <FormHeading pageNumber={9} text="Car Parking" />
            <RadioButtonGroup
              register={register}
              name="provideCarParkingFacilities"
              label="Provide Car Parking Facilities"
              description="Do you provide car parking facilities to employees or directors if there is a commercial all-day park within one kilometre radius that charges more than $8.95 a day at the beginning of the FBT year?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <FormHeading pageNumber={10} text="Entertainment" />
            <RadioButtonGroup
              register={register}
              name="paidEntertainmentExpenses"
              label="Have you paid any entertainment expenses?"
              description="If yes, complete the Entertainment Schedule."
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            {watchPaidEntertainmentExpenses === "true" && (
              <>
                <div>
                  <p>
                    Depending on the circumstances, entertainment expenses are
                    treated as:
                  </p>
                  <ul className="list-disc pl-10">
                    <li className="">Tax deductible and not liable to FBT;</li>
                    <li className="">
                      Not deductible and not liable to FBT; or
                    </li>
                    <li className="">Tax deductible and liable to FBT.</li>
                  </ul>
                  The determining factors are:
                  <ul className="list-disc pl-10 ">
                    <li>Where the entertainment took place; and</li>
                    <li>
                      Who were the attendees (employees and associates, clients)
                    </li>
                  </ul>
                  <p className="mt-10">
                    Please provide the following information in respect of all
                    entertainment expenses paid from 1 April 2020 to 31 March
                    2021.
                  </p>
                </div>
                <TableComponent
                  register={register}
                  fields={fields}
                  remove={remove}
                  append={append}
                />
              </>
            )}

            <FormHeading pageNumber={11} text="Residual Benefits" />

            <RadioButtonGroup
              register={register}
              name="paidOtherBenefits"
              label="Have you paid any other benefits to employees or directors not included above?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="companyPaidPersonalExpenses"
              label="Did the company pay any personal expenses on behalf of employees?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="employeeOwedMoneyToCompany"
              label="At any stage during the year did any employee owe money to the company?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="reviewCashBookAndFinancialStatements"
              label="Have you reviewed your cash book and/or financial statements to ensure all employee Fringe Benefits have been brought to account for the FBT year 1 April 2020 to 31 March 2021?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="performFBTReview"
              label="Would you like us to perform an FBT review of your business?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <RadioButtonGroup
              register={register}
              name="salaryPackagingAdvice"
              label="Would you like salary packaging advice to find out how to save tax this financial year?"
              options={[
                { label: "No", value: "false" },
                { label: "Yes", value: "true" },
              ]}
            />

            <hr className="mb-4" />
            <hr className="mb-4" />

            <div>
              <h2 className="font-bold">Declaration</h2>
              <p className="max-w-3xl">
                I/we acknowledge that I/we have understood and answered each of
                the questions above correctly and completely. I/We acknowledge
                that the preparation is based on the financial information
                supplied by me/us and does not involve the verification of that
                information
              </p>
            </div>

            <InputField
              label="Your Name"
              name="yourName"
              register={register}
              errors={errors}
            />

            <div className="py-2">
              <h2 className="mb-2">
                Signature- Please use mouse or touch pad to sign
              </h2>
              <div className="bg-gray-400/20  max-w-max">
                <SignatureCanvas ref={sigCanvasRef} />
              </div>
              <button
                type="button"
                className="bg-gray-400 rounded-md px-4 mt-4 text-sm"
                onClick={() => sigCanvasRef.current?.clear()}
              >
                Clear Signature
              </button>
            </div>

            <div className="bg-gray-400/20 max-w-max">
              <label htmlFor="ipAddress">Your IP Address : </label>
              <input
                className=" bg-lime-600/20"
                {...register("ipAddress")}
                readOnly
                value={ipAddress}
              />
            </div>

            {/* <InputField
              label="Date"
              name="currentDate"
              register={register}
              errors={errors}
              type="date" // This specifies that the input should present a date picker
            /> */}

            <div className="bg-gray-400/20 max-w-max mb-10">
              <label htmlFor="todayDate">Today's Date : </label>
              <input
                className="bg-lime-600/20"
                {...register("todayDate")}
                readOnly
                value={today}
              />
            </div>
            <div className="fixed bottom-0 left-0 w-full bg-zinc-900 p-4 shadow-md">
              <div className="flex gap-10">
                <button
                  type="submit"
                  className="bg-cyan-500 hover:bg-violet-700 hover:text-white text-black font-bold py-2 px-4 rounded pulse-button"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={handleSaveForLater}
                  className=" bg-transparent hover:bg-blue-700 text-cyan-400 font-bold py-2 px-4 rounded"
                >
                  Save for Later
                </button>
                <button
                  type="button"
                  onClick={handleRestoreData}
                  className="  bg-transparent hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Restore Previous Session
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FringeBenfitsTaxQuestionnaire;
