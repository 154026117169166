import React from "react";
// import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  // Route,
  // Link,
} from "react-router-dom";
import FringeBenfitsTaxQuestionnaire from "./form/fringe-benefits-tax-questionnaire";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "form/fringe-benefits-tax-questionnaire",
    element: <FringeBenfitsTaxQuestionnaire />,
  },
]);

createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider router={router} />
);
