import React from "react";
import "./App.css";
import { Link } from "react-router-dom";

function App() {
  return (
    <div className="App w-full">
      <div className="min-h-[20vh]  flex-col w-full text-start p-10 relative overflow-hidden">
        <img
          src="https://images.unsplash.com/photo-1578580620617-c4addbce9fe6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />

        <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>

        <img alt="EEA Advisory Logo" src="/logo.png" />
        <h1 className="text-5xl font-bold text-white mt-10">
          EEA Advisory Forms
        </h1>
        <p className="max-w-5xl mt-5 text-white text-sm">
          The EEA Advisory Forms Portal is a centralized online platform
          designed to streamline the submission and management of various
          compliance and advisory forms for clients of EEA Advisory. It offers a
          user-friendly interface for easy access to a suite of essential
          documents, including regulatory submissions, tax advisories, and
          business consultancy forms.
        </p>
      </div>
      <div className="grid grid-cols-4 gap-4 p-10">
        <div className="bg-slate-800 rounded-md p-10 text-white relative isolate overflow-hidden">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={256}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <h2 className="text-2xl font-bold">
            Fringe Benefits Tax Questionnaire
          </h2>
          <h3 className="text-cyan-400">Year Ending 31 March 2021</h3>
          <hr className="my-5" />
          <p className="text-sm">
            The Fringe Benefits Tax Questionnaire is an essential tool aimed at
            identifying and valuing non-cash benefits provided to employees,
            ensuring accurate tax reporting. It covers a wide range of benefits
            including company cars, health insurance, and educational
            assistance.
          </p>
          <div className="px-10 py-5">
            <Link
              className=" bg-cyan-500 px-10 py-2 rounded-md"
              to={"form/fringe-benefits-tax-questionnaire"}
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
